import {entity_object} from './DataDictionary.js';
import dayjs from 'dayjs';
import {get_public_holidays} from '../integration/Api.js';
import {updateDBItem} from '../integration/DatabaseManager.js';

export const isZero=(value)=>{
    const normalizedValue=value.toString().replace(',','.').trim();
    return Number(normalizedValue) === 0;
}

export const saveUserDataGridParams=(dataGridParams,user_id)=>{
    let update={datagrid_params:dataGridParams};
    updateDBItem({entityType:'User',entityId:user_id,entityData:update})
    .then((data)=>{
        if('error' in data){
            console.log('saveUserDataGridParams error',data.error);
        }
        else{
            console.log('saveUserDataGridParams: column width saved with success');
        }
    });
}
export const listGridUpdated=(dataGridParams,list_name,field,width)=>{
    let dataGridParamsNew=JSON.parse(JSON.stringify(dataGridParams));
    if(!(list_name in dataGridParamsNew)){
        dataGridParamsNew[list_name]={};
    }
    if(field in dataGridParamsNew[list_name]){
        dataGridParamsNew[list_name][field].width=parseFloat(width.toFixed(2))
    }
    else{
        dataGridParamsNew[list_name][field]={width:parseFloat(width.toFixed(2))}
    }
    return dataGridParamsNew;
}

export const sortPageTabs=(pageTabs)=>{
    let pageTabsSortedArray=[];
    for(let tab in pageTabs){
        pageTabsSortedArray.push([tab,pageTabs[tab]]);
    }
    pageTabsSortedArray.sort(function(a,b){
        return a[1].index-b[1].index;
    });
    let pageTabsSortedObj={};
    pageTabsSortedArray.forEach(item=>{
        pageTabsSortedObj[item[0]]=item[1]
    });
    return pageTabsSortedObj;
};

export const adjustDateLocale=(date_to_be_formatted,noTime=false)=>{
    if(noTime===undefined){noTime=false;}
    if(date_to_be_formatted===null||date_to_be_formatted===''||date_to_be_formatted===undefined){
        return '';
    }
    else{
        const formatted_date=new Date(date_to_be_formatted);
        if(noTime){
            formatted_date.setHours(0,0,0,0);
            return formatted_date.toLocaleDateString('fi-FI',{year:'numeric',month:'numeric',day:'numeric'});
        }
        else{
            return formatted_date.toLocaleString('fi-FI',{timeZone:'EET'});
        }
    }
}

export const dayjs_to_ui=(value)=>{
    let day=String(value.$D).length>1?String(value.$D):'0'+String(value.$D);
    let month=String(value.$M+1).length>1?String(value.$M+1):'0'+String(value.$M+1);
    return day+'/'+month+'/'+String(value.$y);
}

export const dayjs_to_db=(value)=>{
    let day=String(value.$D).length>1?String(value.$D):'0'+String(value.$D);
    let month=String(value.$M+1).length>1?String(value.$M+1):'0'+String(value.$M+1);
    return String(value.$y)+'-'+month+'-'+day;
}

export const dateFns_to_db=(date)=>{
    let month=String(date.getMonth()+1).length>1?String(date.getMonth()+1):'0'+String(date.getMonth()+1);
    let day=String(date.getDate()).length>1?String(date.getDate()):'0'+String(date.getDate());
    let year=date.getFullYear();
    return year+'-'+month+'-'+day;
}
export const dateFns_to_ui=(date)=>{
    let month=String(date.getMonth()+1).length>1?String(date.getMonth()+1):'0'+String(date.getMonth()+1);
    let day=String(date.getDate()).length>1?String(date.getDate()):'0'+String(date.getDate());
    let year=date.getFullYear();
    return day+'.'+month+'.'+year;
}

export const currentTimestamp=()=>{
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const milliseconds = String(now.getMilliseconds()).padStart(3, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
}

export const DefaultConfig={
    readOnlyBackgroundColor:'#d3d3d354'
}

export const prepareErrorFields=(entity)=>{
    let dataError={error:{},message:{}}
    if(entity in entity_object){
        if('fields' in entity_object[entity]){
            for(const [key,value] of Object.entries(entity_object[entity].fields)){
                if('required' in value && value.required){
                    dataError.error[key]=false;
                    dataError.message[key]='';
                }
            }
        }
    }
    return dataError;
}

export const getObjectFieldDisplayName=()=>{
    let columns={}
    Object.keys(entity_object).forEach(e=>{
        columns[e]={}
        if('fields' in entity_object[e]){
            for(const [key,value] of Object.entries(entity_object[e].fields)){
                if('label' in value){
                    columns[e][key]=value.label;
                }
                else{
                    columns[e][key]=key;
                }
            }
        }
    });
    return columns;
}

export const prepareDefaultData=(mode,entity)=>{
    let prepared_data={};
    if(entity in entity_object){
        if('fields' in entity_object[entity]){
            for(const [key,value] of Object.entries(entity_object[entity].fields)){
                if(mode in value){
                    prepared_data[key]=value[mode];
                }
                if('value__ui' in value){
                    prepared_data[key+'__ui']=value.value__ui;
                }
            }
        }
        if('joins' in entity_object[entity]){
            for(const [key,value] of Object.entries(entity_object[entity].joins)){
                if(key in entity_object){
                    prepared_data[value.name]={};
                    if('fields' in value){
                        for(const [key2,value2] of Object.entries(value.fields)){
                            if('fields' in entity_object[key]){
                                if(key2 in entity_object[key].fields){
                                    if('default_value_get' in entity_object[key].fields[key2]){
                                        prepared_data[value.name][key2]=entity_object[key].fields[key2].default_value_get;
                                    }
                                }
                            }
                        }
                        
                    }
                }
            }
        }
    }
    if('multi_relationship' in entity_object[entity]){
        entity_object[entity].multi_relationship.forEach(element=>{
            prepared_data[element]=[];
        });
    }
    return prepared_data;
}

export const prepareGetData=(data,entity)=>{
    let prepared_data={};
    if(entity in entity_object){
        if('fields' in entity_object[entity]){
            for(const [key,value] of Object.entries(entity_object[entity].fields)){
                if('null_to' in value){
                    prepared_data[key]=data[key]||value.null_to;
                }
                else{
                    prepared_data[key]=data[key];
                }
            }
        }
        if('joins' in entity_object[entity]){
            for(const [key,value] of Object.entries(entity_object[entity].joins)){
                if(key in entity_object){
                    if(value.name in data){
                        prepared_data[value.name]={name:data[value.name]&&('name' in data[value.name])?data[value.name].name||'':''};
                        for(const [key2,value2] of Object.entries(value.fields)){
                            if('fields' in entity_object[key]){
                                if(key2 in entity_object[key].fields){
                                    if(data[key]){
                                        if(key2 in data[key]){
                                            if('null_to' in entity_object[key].fields[key2]){
                                                prepared_data[value.name][key2]=data[key][key2]||entity_object[key].fields[key2].null_to;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    else{
                        prepared_data[value.name]={};
                        if('fields' in value){
                            for(const [key2,value2] of Object.entries(value.fields)){
                                if('fields' in entity_object[key]){
                                    if(key2 in entity_object[key].fields){
                                        if('default_value_get' in entity_object[key].fields[key2]){
                                            prepared_data[value.name][key2]=entity_object[key].fields[key2].default_value_get;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ['id','created_by','updated_by','created_at','updated_at'].forEach(element=>{
        if(element in data){
            prepared_data[element]=data[element];
        }
    });
    prepared_data.created_at_ui=adjustDateLocale(data.created_at);
    prepared_data.updated_at_ui=adjustDateLocale(data.updated_at);
    
    if('multi_relationship' in entity_object[entity]){
        entity_object[entity].multi_relationship.forEach(element=>{
            if(element in data){
                prepared_data[element]=data[element];
            }
        });
    }
    return prepared_data;
}

export const prepareSetData=(data,entity)=>{
    let prepared_data={}
    if(entity in entity_object){
        if('fields' in entity_object[entity]){
            for(const [key,value] of Object.entries(entity_object[entity].fields)){
                if(key.indexOf("__ui")<0){
                    if(key in data){
                        prepared_data[key]=data[key];
                        if('empty_to' in value &&prepared_data[key]===''){
                            prepared_data[key]=value.empty_to;
                        }
                    }
                }
            }
        }
    }
    if('reference' in prepared_data){
        delete prepared_data.reference;
    }
    return prepared_data;
}

export const time_to_ui=(value)=>{
    let hour=String(value.$H).length>1?String(value.$H):'0'+String(value.$H);
    let minute=String(value.$m+1).length>1?String(value.$m):'0'+String(value.$m);
    return hour+':'+minute;
}

export const time_to_date_ui=(time)=>{
    return time===null?null:dayjs('2023-01-01T'+time);
}

export const validate_required_fields=(row,entity,setError,setErrorMessage,dataError,required_field_error)=>{
    let valid=false;
    let field_error={};
    let field_errorMessage={};
    for(const [key,value] of Object.entries(row)){
        let invalid_value=value===''||value===null;
        
        if(key in entity_object[entity].fields){
            if('ui_type' in entity_object[entity].fields[key]){
                if(entity_object[entity].fields[key].ui_type==='date'){
                    invalid_value=value==='NaN-NaN-NaN'||value===''||value===null;
                }
            }
        }

        if(key in dataError.error&&invalid_value){
            let is_required=true;
            if(typeof entity_object[entity].fields[key].required==='function'){
                is_required=entity_object[entity].fields[key].required(row);
            }
            if(is_required){
                if('required_bypass' in entity_object[entity].fields[key]){
                    invalid_value=!entity_object[entity].fields[key].required_bypass(row);
                }
                if(invalid_value){
                    field_error[key]=true;
                    field_errorMessage[key]=required_field_error;
                }
            }
        }
    }

    if(Object.keys(field_error).length>0){
        setError(old=>(field_error));
        setErrorMessage(old=>(field_errorMessage));
    }
    else{
        valid=true;
    }

    return valid
}

export const sales_order_delivery_price=(value,city)=>{
    const default_delivery_price='20.00';
    const default_min_value=500;
    let price=default_delivery_price;
    let message='Shipping to "'+city+'" with value under '+default_min_value+'€ is charged '+parseInt(default_delivery_price)+'€';

    const area_1={
        min_value:350,
        cities:[
            'Helsinki',
            'Espoo',
            'Vantaa',
            'Kirkkonummi',
            'Vihti',
            'Nurmijärvi',
            'Hyvinkää',
            'Tuusula',
            'Kerava',
            'Sipoo',
            'Järvenpää',
            'Pornainen',
            'Hyvinkää'
        ],
        
    }

    const area_2={
        min_value:400,
        cities:[
            'Tampere',
            'Hämeenlinna',
            'Turku',
            'Lahti',
            'Forssa',
            'Salo'
        ]
    }
    
    const area_3={
        min_value:600,
        cities:[
            'Rovaniemi',
            'Kittilä',
            'Kolari',
            'Sirkka',
            'Kemi'
        ]
    }

    if(city!==''&&city!==null&&city!==undefined){
        if(area_1.cities.includes(city)){
            if(value>=area_1.min_value){
                price='0.00';
                message='Free delivery to "'+city+'" over '+area_1.min_value+'€';
            }
            else{
                message='Shipping to "'+city+'" with value under '+area_1.min_value+'€ is charged '+parseInt(default_delivery_price)+'€';
            }
        }
        else if(area_2.cities.includes(city)){
            if(value>=area_2.min_value){
                price='0.00';
                message='Free delivery to "'+city+'" over '+area_2.min_value+'€';
            }
            else{
                message='Shipping to "'+city+'" with value under '+area_2.min_value+'€ is charged '+parseInt(default_delivery_price)+'€';
            }
        }
        else if(area_3.cities.includes(city)){
            if(value>=area_3.min_value){
                price='0.00';
                message='Free delivery to "'+city+'" over '+area_3.min_value+'€';
            }
            else{
                message='Shipping to "'+city+'" with value under '+area_3.min_value+'€ is charged '+parseInt(default_delivery_price)+'€';
            }
        }
        else if(value>=default_min_value){
            price='0.00';
            message='Free delivery to "'+city+'" over '+default_min_value+'€';
        }
    }

    return {price:price,message:message};
}
const valid_order_date_from_given_date=(date)=>{
    const dateHours=date.getHours();
    const dateWeekDay=date.getDay();
    let returnDate=date;

    if(dateWeekDay===3){//Wed
        if(dateHours<11){//before 11am
            returnDate.setDate(returnDate.getDate()+3);//Delivery on Fri
        }
        else{//after 11a,
            returnDate.setDate(returnDate.getDate()+5);//Delivery on Mon
        }
    }
    else if(dateWeekDay===4){//Thu
        returnDate.setDate(returnDate.getDate()+4);//Delivery on Mon
    }
    else if(dateWeekDay===5){//Fri
        returnDate.setDate(returnDate.getDate()+3);//Delivery on Mon
    }
    else if(dateWeekDay===6){//Sat
        returnDate.setDate(returnDate.getDate()+3);//Delivery on Tue
    }
    else if(dateWeekDay===0){//Sun
        returnDate.setDate(returnDate.getDate()+3);//Delivery on Wed
    }
    else if(dateWeekDay===1){//Mon
        if(dateHours<11){//before 11am
            returnDate.setDate(returnDate.getDate()+2);//Delivery on Wed
        }
        else{//after 11am
            returnDate.setDate(returnDate.getDate()+3);//Delivery on Thu
        }
    }
    else if(dateWeekDay===2){//Tue
        if(dateHours<11){//before 11am
            returnDate.setDate(returnDate.getDate()+2);//Delivery on Thu
        }
        else{//after 11am
            returnDate.setDate(returnDate.getDate()+3);//Delivery on Fri
        }
    }

    return returnDate;
}

export const generate_order_date=()=>{
    let order_date=valid_order_date_from_given_date(new Date());
    let order_date_string=order_date.toLocaleDateString('fi-FI');
    const holidays=[
        '6.12.2023',
        '24.12.2023',
        '25.12.2023',
        '26.12.2023',
        '1.1.2024',
        '6.1.2024',
        '29.3.2024',
        '31.3.2024',
        '1.4.2024',
        '1.5.2024',
        '9.5.2024',
        '19.5.2024',
        '21.6.2024',
        '22.6.2024',
        '2.11.2024',
        '6.11.2024',
        '24.12.2024',
        '25.12.2024',
        '26.12.2024'];
    while(holidays.includes(order_date_string)){
        order_date.setDate(order_date.getDate()+1);//if holiday, try next day
        order_date=valid_order_date_from_given_date(order_date);
        order_date_string=order_date.toLocaleDateString('fi-FI');
    }
    return order_date;
}

export const getTableNameFromEntityName=(entity_name)=>{
    let table_name='';
    switch(entity_name){
        case 'account':
        case 'product':
        case 'sku':
            table_name=entity_name;
            break;
        case 'sales_order':
        case 'storage_order':
            table_name='order';
            break;
        default:
            break;
    }
    return table_name;
}

export async function get_order_date(){
    let currentDate=new Date();
    if(currentDate.getHours()>10){//hours 10:59=10 . . . 11
        currentDate.setDate(currentDate.getDate()+1);//after 11am goes to next day
    }
    currentDate=next_working_day(currentDate);
    let day=0;
    let currentDateFormat=dateFns_to_db(currentDate);
    let currentYear=currentDate.getFullYear();
    let result=await get_public_holidays({year:currentYear})
        .then((data)=>{
            console.log(data);
            if('error' in data){
                console.log('error',data.error);
            }
            else{
                while(is_holliday(currentDateFormat,data)){
                    day++;
                    currentDate.setDate(currentDate.getDate()+day);//goes to next day
                    currentDate=next_working_day(currentDate);//check if weekend to be set to next working day
                    currentDateFormat=dateFns_to_db(currentDate);
                }
                return currentDate;
            }
        });
    console.log('get_order_date',result);
    return result;
}

function is_holliday(date,data){
    let holliday=false;
    for(let i=0;i<data.length;i++){
        if('date' in data[i]){
            if(data[i].date===date){
                holliday=true;
                break;
            }
        }
        else{//if any issue on the api, leave it as today
            break; 
        }
    }
    return holliday;
}

function next_working_day(date){
    let result=date;
    if(result.getDay()===0){
        result.setDate(result.getDate()+1);//if Sunday, it goes to Monday
    }
    else if(result.getDay()===6){
        result.setDate(result.getDate()+2);//if Saturday, it goes to Monday
    }
    return result;
}