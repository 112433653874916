import {useEffect,useState} from "react";
import {useNavigate,useLocation} from 'react-router-dom';
import {FormGroup,FormControlLabel,Switch,Divider,IconButton,InputAdornment,Box,Backdrop,CircularProgress,TextField,Button,Stack,Typography,ButtonGroup,Snackbar,Alert} from '@mui/material';
import Autocomplete,{createFilterOptions} from '@mui/material/Autocomplete';
import {AccountCircle} from '@mui/icons-material';
//import {makeStyles} from "@material-ui/core";
import {createDBItem,getDBList} from '../../integration/DatabaseManager.js';
import ProductPickList from "../Product/ProductPickList";
import PackageTypesPicklist from "../PackageType/PackageTypesPickList";
import BarcodePickList from "../Barcode/BarcodePickList"
import SKUPickList from "./SKUPickList";
import {status_color,entity_object} from '../../utilities/DataDictionary.js';
import {DefaultConfig,prepareDefaultData,validate_required_fields,prepareErrorFields,prepareSetData} from '../../utilities/Utilities.js';
import Barcode from 'react-barcode';
import {useContext} from 'react';
import {ApplicationContext} from '../ApplicationContext.js';
const readOnlyBackgroundColor=DefaultConfig.readOnlyBackgroundColor;
let alertMessage='';
let alertSeverity='success';
const required_field_error='Required Field';
const filter=createFilterOptions();
let listOfTags=[];
const dataPreparedGet=prepareDefaultData('default_value_new','sku');
const dataError=prepareErrorFields('sku');

const SKUNew=({authMenuSubItem,userDataAuthorization})=>{
    const [row,setRow]=useState(dataPreparedGet);
    const [error,setError]=useState(dataError.error);
    const [errorMessage,setErrorMessage]=useState(dataError.message);
    const [label,setLabel]=useState('-');
    const [openBackdrop,setOpenBackdrop]=useState(false);
    const [snackOpened,setSnackOpened]=useState(false);
    const navigate=useNavigate();
    const [pickControl,setPickControl]=useState({add_product:false,package_type:false,barcode:false,add_sku:false});
    const [lovValuesDynamic,setLovValuesDynamic]=useState({PRODUCT_TAG:[]});
    const [productTags,setProductTags]=useState([]);
    const location=useLocation();
    const [isNewFromParent,setIsNewFromParent]=useState(false);
    //const classes=entity_object.sku.useStyles();
    const {globalData}=useContext(ApplicationContext);

    const handleClickPick=(picklist_name)=>{
        setPickControl(prevState=>({...prevState,[picklist_name]:true}));
    };

    const pickListCallback=(picklist_name,picklist_record)=>{
        console.log('picklist_record',picklist_record);
        setPickControl(prevState=>({...prevState,[picklist_name]:false}));
        if(Object.keys(picklist_record).length!==0){
            if(picklist_name==='add_product'){
                let updated_row=JSON.parse(JSON.stringify(row));
                if(updated_row.product_id!==picklist_record.id){
                    updated_row.product=picklist_record;
                    updated_row.product_id=picklist_record.id;
                    setRow(old=>(updated_row));
                    setProductTags(old=>picklist_record.tags||[]);
                    setError(prevState=>({...prevState,product_id:false}));
                    setErrorMessage(prevState=>({...prevState,product_id:''}));
                }
            }
            else if(picklist_name==='package_type'){
                let updated_row=JSON.parse(JSON.stringify(row));
                if(updated_row.product_id!==picklist_record.id){
                    updated_row.pack_type=picklist_record.type;
                    //updated_row.pack_size=picklist_record.size;
                    updated_row.pack_unit=picklist_record.unit;
                    updated_row.pack_amount=picklist_record.amount;
                    updated_row.width=picklist_record.width;
                    updated_row.height=picklist_record.height;
                    updated_row.length=picklist_record.length;
                    updated_row.weight=picklist_record.weight;
                    setRow(old=>(updated_row));
                    let event={target:{name:'pack_type',value:picklist_record.type}}
                    handleChange(event);
                    //event={target:{name:'pack_size',value:picklist_record.size}}
                    //handleChange(event);
                    event={target:{name:'pack_unit',value:picklist_record.unit}}
                    handleChange(event);
                    event={target:{name:'pack_amount',value:picklist_record.amount}}
                    handleChange(event);
                    event={target:{name:'width',value:picklist_record.width}}
                    handleChange(event);
                    event={target:{name:'height',value:picklist_record.height}}
                    handleChange(event);
                    event={target:{name:'length',value:picklist_record.length}}
                    handleChange(event);
                    event={target:{name:'weight',value:picklist_record.weight}}
                    handleChange(event);
                }
            }
            else if(picklist_name==='barcode'){
                let updated_row=JSON.parse(JSON.stringify(row));
                if(updated_row.gtin_id!==picklist_record.id){
                    updated_row.gtin_id=picklist_record.id;
                    updated_row.gtin.name=picklist_record.name;
                    setRow(old=>(updated_row));
                    let event={target:{name:'gtin_id',value:picklist_record.id}}
                    handleChange(event);
                }
            }
            else if(picklist_name==='add_sku'){
                console.log('picklist_record',picklist_record);
                setRow(prevState=>({...prevState,
                    description:picklist_record.product.description||'',
                    description_internal:picklist_record.product.description_internal||'',
                    product:picklist_record.product,
                    product_id:picklist_record.product.id,
                    parent_sku_id:picklist_record.id,
                    palpa:picklist_record.palpa,
                    palpa_value:picklist_record.palpa_value,
                    parent_sku:{name:picklist_record.name,palpa_value:picklist_record.palpa_value}
                }));
                setError(prevState=>({...prevState,
                    parent_sku_id:false,
                    product_id:false}));
                setErrorMessage(prevState=>({...prevState,
                    parent_sku_id:'',
                    product_id:''}));
                setProductTags(old=>picklist_record.product.tags||[]);
            }
        }
    };

    const handleCloseSnack=()=>{
        setSnackOpened(false);
    }

    const handleChangeSwitch=(event)=>{
        handleChange(event={target:{name:event.target.name,value:event.target.checked}});
    }

    useEffect(()=>{
        if(Object.keys(authMenuSubItem).length>0){
            setLabel(authMenuSubItem.label);
            if('state' in location){
                if(location.state){
                    if('parent_sku' in location.state){
                        console.log('location.state.parent_sku',location.state.parent_sku);
                        setIsNewFromParent(old=>true);
                        setRow(prevState=>({...prevState,
                            product:location.state.parent_sku.product,
                            description:location.state.parent_sku.product.description,
                            description_internal:location.state.parent_sku.product.description_internal,
                            product_id:location.state.parent_sku.product.id,
                            parent_sku_id:location.state.parent_sku.id,
                            parent_sku:{name:location.state.parent_sku.name,palpa_value:location.state.parent_sku.palpa_value},
                            palpa:location.state.parent_sku.palpa,
                            palpa_value:location.state.parent_sku.palpa_value,
                            type:'Multipack'}));
                            setProductTags(old=>location.state.parent_sku.product.tags||[]);
                    }
                    else if('product' in location.state){
                        setIsNewFromParent(old=>true);
                        console.log('location.state.product',location.state.product);
                        setRow(prevState=>({...prevState,
                            product:location.state.product,
                            description:location.state.product.description,
                            description_internal:location.state.product.description_internal,
                            product_id:location.state.product.id,
                            type:'Single'}));
                            setProductTags(old=>location.state.product.tags||[]);
                    }
                }
            }
            listOfTags=globalData.listOfValues.PRODUCT_TAG||[];
            let tagValues=[];
            listOfTags.forEach(element=>{
                tagValues.push({title:element});
            });
            setLovValuesDynamic({PRODUCT_TAG:tagValues});//separated to be updated as new values are inserted
        }
    },[authMenuSubItem]);

    const createRecord=()=>{
        setOpenBackdrop(true);
        let row_create=prepareSetData(JSON.parse(JSON.stringify(row)),'sku');
        let tags=[];
        productTags.forEach(element=>{
            if(typeof element!=='string'){
                if('inputValue' in element){
                    tags.push(element.inputValue);
                }
                else if('title' in element){
                    tags.push(element.title);
                }
            }
            else{
                tags.push(element);
            }
        });
        row_create.tags=tags;
        console.log('row_create',row_create);
        createDBItem({entityType:authMenuSubItem.query,entityData:row_create})
        .then((data)=>{
            setOpenBackdrop(false);
            let newId=null;
            console.log('data',data);
            if('error' in data){
                alertSeverity='error'
                alertMessage=data.error;
                if('id' in data){newId=data.id;}
            }
            else{
                newId=data.id;
                alertSeverity='success';
                alertMessage='SKU created with success!!!';
            }
            if(newId){
                let message={alertSeverity:alertSeverity,alertMessage:alertMessage};
                if('parent_sku_id' in row&&row.parent_sku_id!==''){
                    navigate('/sku/'+newId+'/product',{state:{message:message}});
                }
                else{
                    navigate('/sku/'+newId+'/multipack',{state:{message:message}});
                }
            }
            else{
                setSnackOpened(true);
            }
        });
    };

    const handleClickSave=()=>{
        if(validate_required_fields(row,'sku',setError,setErrorMessage,dataError,required_field_error)){
            if(row.type==='Single'&&row.pack_type==='Can'){
                getDBList({entityType:'SKU',entityParams:['product_id='+row.product_id,'type=Single','pack_type_and=Can']})
                .then((data)=>{
                    console.log('SKU Single Validation',data);
                    if('error' in data){
                        console.log('error',data.error);
                    }
                    else{
                        if(data.length>0){
                            const promptResponse=window.prompt("There is already a SKU with type 'Single' and package type 'Can' for this Product. If you want to proceed with this record creation please type 'single':");
                            if(promptResponse==='single'){
                                preCreateRecord();
                            }
                            else if(promptResponse!==null&&promptResponse!=='single'){
                                alertMessage="Invalid response. Please type 'single' to proceed";
                                alertSeverity='error';
                                setSnackOpened(true);
                            }
                        }
                        else{
                            preCreateRecord();
                        }
                    }
                });
                
            }
            else{
                preCreateRecord();
            }
        }
        else{
            alertSeverity='error'
            alertMessage='Please populate the required fields';
            setSnackOpened(true);
        }
    }

    const preCreateRecord=()=>{
        if(row.palpa==='DepositBasedReturnSystem'){
            const promptResponse=window.prompt("Plase confirm if SKU is set for palpa deposit by typing 'palpa':");
            if(promptResponse==='palpa'){
                createRecord();
            }
            else if(promptResponse!==null&&promptResponse!=='palpa'){
                alertMessage="Invalid response. Please type 'palpa' to proceed";
                alertSeverity='error';
                setSnackOpened(true);
            }
        }
        else{
            createRecord();
        }
    }

    const updateDynamicListOfValues=(value)=>{
        let row_insert={type:'PRODUCT_TAG',status:'Active',sorting_order:0,value:value};
        createDBItem({entityType:'ListOfValues',entityData:row_insert})
        .then((data)=>{  
            if('error' in data){console.log('error',data.error);}
            else{
                console.log('insert',data);
                let newId=data.id;
                console.log('newId',newId);
                listOfTags.push(value);
                let tagValues=JSON.parse(JSON.stringify(lovValuesDynamic.PRODUCT_TAG));
                tagValues.push({title:value});
                setLovValuesDynamic(prevState=>({...prevState,PRODUCT_TAG:tagValues}));
            }
        });
    };

    const handleClickCancel=()=>{
        navigate(-1);
    }

    const handleChangeList=(fieldValue,fieldName)=>{
        let event={target:{name:fieldName,value:fieldValue}}
        handleChange(event);

        if('events' in entity_object.sku.fields[fieldName]){
            if('onChange' in entity_object.sku.fields[fieldName].events){
                entity_object.sku.fields[fieldName].events.onChange(fieldValue,handleChangeList,JSON.parse(JSON.stringify(row)));
            }
        }

        if('drive_dynamic_required' in entity_object.sku.fields[fieldName]){
            let row_update=JSON.parse(JSON.stringify(row));
            row_update[fieldName]=fieldValue;
            for(const [key,value] of Object.entries(entity_object.sku.fields)){
                if(key!==fieldName){
                    if('required' in value){
                        if(typeof value.required==='function'){
                            let required=entity_object.sku.fields[key].required(row_update);
                            setError(prevState=>({...prevState,[key]:required&&(row_update[key]==='')}));
                            setErrorMessage(prevState=>({...prevState,[key]:required&&(row_update[key]==='')?required_field_error:''}));
                        }
                    }
                }
            }
        }
    };

    const handleChange=(event)=>{
        setRow(prevState=>({...prevState,[event.target.name]:event.target.value}));
        if(event.target.name in error){
            if(error[event.target.name]){
                setError(prevState=>({...prevState,[event.target.name]:event.target.value==''}));
                setErrorMessage(prevState=>({...prevState,[event.target.name]:event.target.value==''?required_field_error:''}));
            }
        }
        if('events' in entity_object.sku.fields[event.target.name]){
            if('onChange' in entity_object.sku.fields[event.target.name].events){
                entity_object.sku.fields[event.target.name].events.onChange(event.target.value,handleChangeList,JSON.parse(JSON.stringify(row)));
            }
        }
    };

    return (
        <div className="page">
            <Snackbar open={snackOpened} autoHideDuration={5000} onClose={handleCloseSnack} anchorOrigin={{vertical:'bottom',horizontal:'center'}}>
                <Alert variant="filled" severity={alertSeverity}>{alertMessage}</Alert>
            </Snackbar>
            <Backdrop sx={{color:'#fff',zIndex:(theme)=>theme.zIndex.drawer+1}} open={openBackdrop}><CircularProgress color="inherit"/></Backdrop>
            <Box sx={{display:'flex',flexDirection:'flex-end','& > *':{m:1,},}}>
                <Typography variant="h6" style={{margin:'5px'}}>{label}</Typography>
            </Box>
            <div className="page-content">
                {pickControl.add_product&&<ProductPickList pickListCallback={pickListCallback} query_param={authMenuSubItem.picklists.product_name} globalData={globalData} userDataAuthorization={userDataAuthorization}></ProductPickList>}
                {pickControl.package_type&&<PackageTypesPicklist pickListCallback={pickListCallback} authMenuSubItem={authMenuSubItem} sku_type={row.type} globalData={globalData} userDataAuthorization={userDataAuthorization}></PackageTypesPicklist>}
                {pickControl.barcode&&<BarcodePickList pickListCallback={pickListCallback} authMenuSubItem={authMenuSubItem} globalData={globalData} userDataAuthorization={userDataAuthorization}></BarcodePickList>}
                {pickControl.add_sku&&<SKUPickList pickListCallback={pickListCallback} query_param={authMenuSubItem.picklists.sku} globalData={globalData} userDataAuthorization={userDataAuthorization}></SKUPickList>}
                <Box component="form" noValidate autoComplete="off">
                    <ButtonGroup size="small">
                        <Button name="save" variant="outlined" onClick={handleClickSave}>Save</Button>
                        <Button name="cancel" variant="outlined" onClick={handleClickCancel}>Cancel</Button>
                    </ButtonGroup>
                    <Stack style={{paddingTop:'20px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            required={entity_object.sku.fields.name.required}
                            error={error.name}
                            helperText={errorMessage.name}
                            id="name"
                            name="name"
                            type={entity_object.sku.fields.name.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sku.fields.name.label}
                            value={row.name}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{color:status_color[row.status],backgroundColor:readOnlyBackgroundColor}}}
                            InputProps={{readOnly:true}}
                            required
                            id="status"
                            name="status"
                            type={entity_object.sku.fields.status.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sku.fields.status.label}
                            value={row.status}
                            margin="normal"/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            InputProps={{readOnly:true}}
                            id="reference"
                            name="reference"
                            type={entity_object.sku.fields.reference.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={row.reference}
                            label={entity_object.sku.fields.reference.label}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            InputProps={{readOnly:true}}
                            id="stock_balance"
                            name="stock_balance"
                            type={entity_object.sku.fields.stock_balance.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={row.stock_balance}
                            label={entity_object.sku.fields.stock_balance.label}
                            margin="normal"/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            InputProps={{readOnly:true}}
                            id="quarantined_balance"
                            name="quarantined_balance"
                            type={entity_object.sku.fields.quarantined_balance.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={row.quarantined_balance}
                            label={entity_object.sku.fields.quarantined_balance.label}
                            margin="normal"/>
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            readOnly={row.parent_sku_id!==''||row.product_id!==''}
                            fullWidth
                            options={globalData.listOfValues.SKU_TYPE||[]}
                            getOptionLabel={(option)=>option}
                            id="type"
                            name="type"
                            type={entity_object.sku.fields.type.ui_type}
                            size="small"
                            value={row.type}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'type');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    sx={{div:{color:status_color[row.type],backgroundColor:(row.parent_sku_id!==''||row.product_id!=='')?readOnlyBackgroundColor:''}}}
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    required={entity_object.sku.fields.type.required}
                                    error={error.type}
                                    helperText={errorMessage.type}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.sku.fields.type.label}
                                    margin="normal"
                                />
                            )}
                        />
                    </Stack>
                    {row.type==='Multipack'&&<>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{div:{backgroundColor:isNewFromParent?readOnlyBackgroundColor:''}}}
                            InputProps={{
                                readOnly:isNewFromParent,
                                startAdornment:(
                                    !isNewFromParent&&
                                    <InputAdornment position="start">
                                        <IconButton onClick={(e)=>handleClickPick('add_sku')}>
                                            <AccountCircle/>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            required={entity_object.sku.fields.parent_sku_id.required}
                            error={error.parent_sku_id}
                            helperText={errorMessage.parent_sku_id}
                            id="parent_sku_name"
                            name="parent_sku_name"
                            type={entity_object.sku.fields.name.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sku.joins.sku.fields.name.label}
                            value={row.parent_sku.name}
                            margin="normal"/>
                    </Stack>
                    </>}
                    {row.type!==null&&
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{div:{backgroundColor:(isNewFromParent||row.type==='Multipack')?readOnlyBackgroundColor:''}}}
                            InputProps={{
                                readOnly:true,
                                startAdornment:(
                                    !(isNewFromParent||row.type==='Multipack')&&
                                    <InputAdornment position="start">
                                        <IconButton onClick={(e)=>handleClickPick('add_product')}>
                                            <AccountCircle/>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            required={entity_object.sku.fields.product_id.required}
                            error={error.product_id}
                            helperText={errorMessage.product_id}
                            id="product_name"
                            name="product_name"
                            type={entity_object.product.fields.name.ui_type}
                            //required={!(isNewFromParent||row.type==='Multipack')}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sku.joins.product.fields.name.label}
                            value={row.product.name}
                            margin="normal"
                            onChange={handleChange}/>
                        <FormGroup style={{paddingLeft:'10px'}}>
                            <FormControlLabel control={<Switch name='sellable' checked={row.sellable} onChange={handleChangeSwitch}/>} label={entity_object.sku.fields.sellable.label}/>
                        </FormGroup>
                    </Stack>}
                    
                    {(row.product.name!==''||row.parent_sku_id!=='')&&<>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            fullWidth
                            freeSolo
                            multiple
                            options={lovValuesDynamic.PRODUCT_TAG}
                            id="tags"
                            name="tags"
                            type={entity_object.sku.fields.tags.ui_type}
                            size="small"
                            value={productTags}
                            ChipProps={{color:"primary"}}
                            onChange={(event,newValue)=>{
                                newValue.forEach(element=>{
                                    if(typeof element!=='string'){
                                        if('inputValue' in element){
                                            if(!listOfTags.includes(element.inputValue)){
                                                updateDynamicListOfValues(element.inputValue);
                                            }
                                        }
                                    }
                                });
                                setProductTags(newValue);
                            }}
                            filterOptions={(options,params)=>{
                                const filtered=filter(options,params);
                                const {inputValue}=params;
                                const isExisting=options.some((option)=>inputValue===option.title);
                                if(inputValue!==''&&!isExisting){
                                    filtered.push({
                                        inputValue,
                                        title:`Add "${inputValue}"`,
                                    });
                                }
                                return filtered;
                            }}
                            getOptionLabel={(option)=>{
                                if(typeof option==='string'){
                                  return option;
                                }
                                if(option.inputValue){
                                  return option.inputValue;
                                }
                                return option.title;
                            }}
                            renderOption={(props,option)=>{
                                let bAdd=true;
                                productTags.every(element=>{
                                    if(typeof element!=='string'){
                                        if('inputValue' in element){
                                            bAdd=element.inputValue!==option.title;
                                        }
                                        else if('title' in element){
                                            bAdd=element.title!==option.title;
                                        }
                                    }
                                    return bAdd;
                                });
                                if(!productTags.includes(option.title)&&bAdd){
                                    return <li {...props}>{option.title}</li>;
                                }
                            }}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    label={entity_object.sku.fields.tags.label}
                                    margin="normal"/>
                            )}
                        />
                    </Stack>
                    <Divider /*className={classes.storage}*/ style={{marginTop:"5px"}} textAlign="left"><Typography color={entity_object.sku.style.storage_color} gutterBottom variant="body1">Product Info</Typography></Divider>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sku.style.storage_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            readOnly={true}
                            sx={{span:{color:'#1976d2'}}}
                            fullWidth
                            freeSolo
                            options={['']}
                            id="product_category"
                            name="product_category"
                            type={entity_object.product.fields.category.ui_type}
                            size="small"
                            value={row.product.category}
                            ChipProps={{color:"primary"}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    sx={{div:{backgroundColor:readOnlyBackgroundColor}}}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    label={entity_object.product.fields.category.label}
                                    margin="normal"/>
                            )}
                        />
                        {/*<Autocomplete
                            style={{marginTop:"0px"}}
                            readOnly={true}
                            sx={{span:{color:'#1976d2'}}}
                            fullWidth
                            multiple
                            freeSolo
                            options={['']}
                            id="product_categories"
                            name="product_categories"
                            type={entity_object.product.fields.categories.ui_type}
                            size="small"
                            value={row.product.categories}
                            ChipProps={{color:"primary"}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    sx={{div:{backgroundColor:readOnlyBackgroundColor}}}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    label={entity_object.product.fields.categories.label}
                                    margin="normal"/>
                            )}
                        />
                    </Stack>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sku.style.storage_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>*/}
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            InputProps={{readOnly:true}}
                            id="product_temperature_min"
                            name="product_temperature_min"
                            type={entity_object.product.fields.temperature_min.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.product.fields.temperature_min.label}
                            value={row.product.temperature_min}
                            margin="normal"/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            InputProps={{readOnly:true}}
                            id="product_temperature_max"
                            name="product_temperature_max"
                            type={entity_object.product.fields.temperature_max.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.product.fields.temperature_max.label}
                            value={row.product.temperature_max}
                            margin="normal"/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            InputProps={{readOnly:true}}
                            id="product_min_usage_days"
                            name="product_min_usage_days"
                            type={entity_object.product.fields.min_usage_days.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.product.fields.min_usage_days.label}
                            value={row.product.min_usage_days}
                            margin="normal"/>
                    </Stack>    
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sku.style.storage_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}> 
                        <FormGroup style={{paddingLeft:'10px'}}>
                            <FormControlLabel control={<Switch disabled name='product_track_batches' checked={row.product.track_batches}/>} label={entity_object.product.fields.track_batches.label}/>
                        </FormGroup>
                        <FormGroup style={{paddingLeft:'10px'}}>
                            <FormControlLabel control={<Switch disabled name='product_track_expiration_date' checked={row.product.track_expiration_date}/>} label={entity_object.product.fields.track_expiration_date.label}/>
                        </FormGroup>
                    </Stack>
                    <Divider /*className={classes.package}*/ style={{marginTop:"5px"}} textAlign="left"><Typography color={entity_object.sku.style.package_color} gutterBottom variant="body1">Package</Typography></Divider>
                    <Button name="package_type" variant="outlined" onClick={(e)=>handleClickPick('package_type')}>Load Package Type</Button>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sku.style.package_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>                        
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            fullWidth
                            options={globalData.listOfValues.PACKAGE_TYPE||[]}
                            getOptionLabel={(option)=>option}
                            id="pack_type"
                            name="pack_type"
                            type={entity_object.sku.fields.pack_type.ui_type}
                            size="small"
                            value={row.pack_type}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'pack_type');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    required={entity_object.sku.fields.pack_type.required}
                                    error={error.pack_type}
                                    helperText={errorMessage.pack_type}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.sku.fields.pack_type.label}
                                    margin="normal"
                                />
                            )}
                        />
                        {/*
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            required={entity_object.sku.fields.pack_size.required}
                            error={error.pack_size}
                            helperText={errorMessage.pack_size}
                            id="pack_size"
                            name="pack_size"
                            type={entity_object.sku.fields.pack_size.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sku.fields.pack_size.label}
                            value={row.pack_size}
                            margin="normal"
                            onChange={handleChange}/>
                            */}
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            fullWidth
                            options={globalData.listOfValues.PACKAGE_UNIT||[]}
                            getOptionLabel={(option)=>option}
                            id="pack_unit"
                            name="pack_unit"
                            type={entity_object.sku.fields.pack_unit.ui_type}
                            size="small"
                            value={row.pack_unit}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'pack_unit');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    required={entity_object.sku.fields.pack_unit.required}
                                    error={error.pack_unit}
                                    helperText={errorMessage.pack_unit}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.sku.fields.pack_unit.label}
                                    margin="normal"
                                />
                            )}
                        />
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            required={entity_object.sku.fields.pack_amount.required}
                            error={error.pack_amount}
                            helperText={errorMessage.pack_amount}
                            id="pack_amount"
                            name="pack_amount"
                            type={entity_object.sku.fields.pack_amount.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sku.fields.pack_amount.label}
                            value={row.pack_amount}
                            margin="normal"
                            onChange={handleChange}/>
                    {/*</Stack>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sku.style.package_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>*/}
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            required={entity_object.sku.fields.width.required}
                            error={error.width}
                            helperText={errorMessage.width}
                            id="width"
                            name="width"
                            type={entity_object.sku.fields.width.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sku.fields.width.label}
                            value={row.width}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            required={entity_object.sku.fields.height.required}
                            error={error.height}
                            helperText={errorMessage.height}
                            id="height"
                            name="height"
                            type={entity_object.sku.fields.height.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sku.fields.height.label}
                            value={row.height}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            required={entity_object.sku.fields.length.required}
                            error={error.length}
                            helperText={errorMessage.length}
                            id="length"
                            name="length"
                            type={entity_object.sku.fields.length.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sku.fields.length.label}
                            value={row.length}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            required={entity_object.sku.fields.weight.required}
                            error={error.weight}
                            helperText={errorMessage.weight}
                            id="weight"
                            name="weight"
                            type={entity_object.sku.fields.weight.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sku.fields.weight.label}
                            value={row.weight}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Divider /*className={classes.palpa}*/ style={{marginTop:"5px"}} textAlign="left"><Typography color={entity_object.sku.style.palpa_color} gutterBottom variant="body1">Palpa</Typography></Divider>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sku.style.palpa_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            sx={{span:{color:(entity_object.sku.fields.palpa.readonly(row))?'#1976d2':""}}}
                            readOnly={(entity_object.sku.fields.palpa.readonly(row))}
                            fullWidth
                            options={globalData.listOfValues.PALPA||[]}
                            getOptionLabel={(option)=>option}
                            id="palpa"
                            name="palpa"
                            type={entity_object.sku.fields.palpa.ui_type}
                            size="small"
                            value={row.palpa}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'palpa');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    sx={{div:{backgroundColor:(entity_object.sku.fields.palpa.readonly(row))?readOnlyBackgroundColor:""}}}
                                    required={entity_object.sku.fields.palpa.required(row)}
                                    error={error.palpa}
                                    helperText={errorMessage.palpa}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.sku.fields.palpa.label}
                                    margin="normal"
                                />
                            )}
                        />
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:(entity_object.sku.fields.palpa_value.readonly(row))?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:(entity_object.sku.fields.palpa_value.readonly(row))}}
                            required={entity_object.sku.fields.palpa_value.required(row)}
                            error={error.palpa_value}
                            helperText={errorMessage.palpa_value}
                            id="palpa_value"
                            name="palpa_value"
                            type={entity_object.sku.fields.palpa_value.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sku.fields.palpa_value.label}
                            value={row.palpa_value}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Divider /*className={classes.price}*/ style={{marginTop:"5px"}} textAlign="left"><Typography color={entity_object.sku.style.price_color} gutterBottom variant="body1">Pricing</Typography></Divider>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sku.style.price_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            required={entity_object.sku.fields.price.required}
                            error={error.price}
                            helperText={errorMessage.price}
                            id="price"
                            name="price"
                            type={entity_object.sku.fields.price.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sku.fields.price.label}
                            value={row.price}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            InputProps={{readOnly:true}}
                            id="currency_code"
                            name="currency_code"
                            type={entity_object.sku.fields.currency_code.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sku.fields.currency_code.label}
                            value={row.currency_code}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Divider /*className={classes.barcode}*/ style={{marginTop:"5px"}} textAlign="left"><Typography color={entity_object.sku.style.barcode_color} gutterBottom variant="body1">Barcode</Typography></Divider>
                    <Button color={error.gtin_id?'error':'primary'} name="barcode" variant="outlined" onClick={(e)=>handleClickPick('barcode')}>{row.gtin.name===''?'Add Barcode':'Update Barcode'}</Button>
                    <Stack style={{paddingTop:'10px',paddingBottom:'10px',paddingLeft:'10px',backgroundColor:entity_object.sku.style.barcode_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        {row.gtin.name!=''&&<Barcode value={row.gtin.name} height={25}/>}
                        {error.gtin_id&&<Typography color='error' gutterBottom variant="h6">Barcode is Required on the SKU!</Typography>}
                    </Stack>
                    <Stack style={{paddingTop:'20px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            inputProps={{maxLength:500}}
                            multiline={true}
                            rows={5}
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            id="description"
                            name="description"
                            type={entity_object.sku.fields.description.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sku.fields.description.label}
                            value={row.description}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            multiline={true}
                            rows={5}
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            id="description_internal"
                            name="description_internal"
                            type={entity_object.sku.fields.description_internal.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sku.fields.description_internal.label}
                            value={row.description_internal}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack></>}
                </Box>
            </div>
        </div>
    );
}

export default SKUNew;
