import {useEffect,useState} from "react";
import {useParams,useNavigate,useLocation} from 'react-router-dom';
import {Tooltip,Switch,FormGroup,FormControlLabel,Box,Tabs,Tab,TextField,Button,Stack,Typography,ButtonGroup,Snackbar,Alert,IconButton,InputAdornment,Backdrop,CircularProgress} from '@mui/material';
import Autocomplete,{createFilterOptions} from '@mui/material/Autocomplete';
import {ArrowCircleUp,ArrowCircleDown,ArrowCircleRight} from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {DataGrid,GridToolbarContainer,GridToolbarQuickFilter} from '@mui/x-data-grid';
import InfoIcon from '@mui/icons-material/Info';
import RecordInfo from "../RecordInfo"
import {sortPageTabs,prepareGetData,prepareSetData,prepareDefaultData,validate_required_fields,prepareErrorFields,DefaultConfig,isZero} from '../../utilities/Utilities';
import {getDBItem,updateDBItem,createDBItem} from '../../integration/DatabaseManager.js';
import AccountPickList from "../Account/AccountPickList.js";
import ProductDetailSKUs from "./ProductDetailSKUs";
import ProductDetailBatches from "./ProductDetailBatches";
import {entity_object} from '../../utilities/DataDictionary';
import {useContext} from 'react';
import {ApplicationContext} from '../ApplicationContext.js';
import {check_duplicated} from '../../integration/Api.js';
import Audit from '../Audit.js';
import {columns} from '../../utilities/Entities';
const readOnlyBackgroundColor=DefaultConfig.readOnlyBackgroundColor;
let alertMessage='';
const hideMoreInfoLabel='Hide More Info';
const showMoreInfoLabel='Show More Info';
let alertSeverity='error'
const required_field_error='Required Field';
const filter=createFilterOptions();
let listOfTags=[];
const dataPreparedGet=prepareDefaultData('default_value_get','product');
const dataError=prepareErrorFields('product');
let duplicated_name={};
let pagehistory=null;
const ProductDetail=({authMenuSubItem,userDataAuthorization})=>{
    const [row,setRow]=useState(dataPreparedGet);
    const [error,setError]=useState(dataError.error);
    const [errorMessage,setErrorMessage]=useState(dataError.message);
    const [label,setLabel]=useState('-');
    const {slug}=useParams();
    const [pageTab,setPageTab]=useState(0);
    const [pageTabs,setPageTabs]=useState({});
    const [isChanged,setIsChanged]=useState(false);
    const [snackOpened,setSnackOpened]=useState(false);  
    const navigate=useNavigate();
    const location=useLocation();
    const [readOnly,setReadOnly]=useState(true);
    const [updates,setUpdates]=useState({});
    const [showMoreInfo,setShowMoreInfo]=useState(false);
    const [recordInfo,setRecordInfo]=useState(false);
    const [actions,setActions]=useState({});
    const [pickControl,setPickControl]=useState({account_name:false});
    const [lovValuesDynamic,setLovValuesDynamic]=useState({PRODUCT_TAG:[]});
    const [productTags,setProductTags]=useState([]);
    const [openBackdrop,setOpenBackdrop]=useState(false);
    const {globalData}=useContext(ApplicationContext);
    const [refreshPageTab,setRefreshPageTab]=useState(false);
    const [openDialog,setOpenDialog]=useState(false);

    const handleClickMoreInfo=()=>{
        setRecordInfo(true);
    };

    const recordInfoCallback=()=>{
        setRecordInfo(false);
    };

    const handleShowMoreInfo=()=>{
        setShowMoreInfo(!showMoreInfo);
    }

    const handleCloseSnack=()=>{
        setSnackOpened(false);
    }

    const pickListCallback=(picklist_name,picklist_record)=>{
        setPickControl(prevState=>({...prevState,[picklist_name]:false}));
        if(Object.keys(picklist_record).length!==0){
            if(picklist_name==='account_name'){
                let updated_row=JSON.parse(JSON.stringify(row));
                if(updated_row.account_id!==picklist_record.id){
                    updated_row.account.name=picklist_record.name;
                    updated_row.account_id=picklist_record.id;
                    setRow(old=>(updated_row));
                    let event={target:{name:'account_id',value:picklist_record.id}}
                    handleChange(event);
                }
            }
        }
    };

    const handleChangeTab=(event,newValue)=>{
        event.currentTarget.blur();
        setPageTab(newValue);
        pagehistory=event.target.name;
        navigate(location.pathname.replace(location.pathname.split('/')[3],pageTabs[event.target.name].path));
    };

    const getRecord=(query)=>{
        getDBItem({entityType:query,entityId:slug})
        .then((data)=>{
            if('error' in data){
		        console.log('error',data.error);
                alertMessage=data.error;
                alertSeverity='error';
                setReadOnly(true);
                setSnackOpened(true);
	        }
            else{
                if(data!=null){
                    console.log('data',data);
                    setProductTags(old=>data.tags||[]);
                    setRow(old=>prepareGetData(data,'product'));
                    if(authMenuSubItem){
                        setLabel(authMenuSubItem.label);
                        setReadOnly(!('edit' in authMenuSubItem.actions));
                    }
                }
                else{
                    alertMessage='Record not found or not accessible';
                    alertSeverity='error'
                    setReadOnly(true);
                    setSnackOpened(true);
                }
            }
        });
    };

    const pageTabCallback=(command)=>{
        if(command==='refreshed'){
            setRefreshPageTab(false);
        }
    }

    const preUpdateRecord=()=>{
        if('name' in updates){
            checkDuplicated();
        }
        else{
            updateRecord();
        }
    };

    const updateRecord=()=>{
        setOpenBackdrop(old=>true);
        setOpenDialog(old=>false);
        let row_update=prepareSetData(JSON.parse(JSON.stringify(updates)),'product');
        let tags=[];
        productTags.forEach(element=>{
            if(typeof element!=='string'){
                if('inputValue' in element){
                    tags.push(element.inputValue);
                }
                else if('title' in element){
                    tags.push(element.title);
                }
            }
            else{
                tags.push(element);
            }
        });
        row_update.tags=tags;
        console.log('row_update',row_update);

        updateDBItem({entityType:authMenuSubItem.query,entityId:slug,entityData:row_update})
        .then((data)=>{  
            if('error' in data){
                console.log('error',data.error);
                alertSeverity='error'
                alertMessage=data.error;
	        }
            else{
                alertSeverity='success'
                alertMessage='Product updated with success!!!';
            }
            setSnackOpened(true);
            setIsChanged(false);
            getRecord(authMenuSubItem.query);
            setOpenBackdrop(false);
            setRefreshPageTab(true);
        });
        setUpdates(old=>{});
    };

    const updateDynamicListOfValues=(value)=>{
        let row_insert={type:'PRODUCT_TAG',status:'Active',sorting_order:0,value:value};
        createDBItem({entityType:'ListOfValues',entityData:row_insert})
        .then((data)=>{ 
            if('error' in data){console.log('error',data.error);}
            else{
                console.log('insert',data);
                let newId=data.id;
                console.log('newId',newId);
                listOfTags.push(value);
                let tagValues=JSON.parse(JSON.stringify(lovValuesDynamic.PRODUCT_TAG));
                tagValues.push({title:value});
                setLovValuesDynamic(prevState=>({...prevState,PRODUCT_TAG:tagValues}));
            }
        });
    };

    useEffect(()=>{
        if(Object.keys(userDataAuthorization).length>0&&Object.keys(authMenuSubItem).length>0&&(authMenuSubItem!==undefined)&&(slug!==undefined&&slug!==null&&slug!=='')&&location){
            setActions(authMenuSubItem.actions);
            getRecord(authMenuSubItem.query);
            setPageTabs(old=>sortPageTabs(authMenuSubItem.pageTabs));
            if(!location.pathname.includes(authMenuSubItem.defaultTabPath)||(pagehistory&&(pagehistory!==authMenuSubItem.defaultTabPath))){
                for(let key in authMenuSubItem.pageTabs){
                    if(location.pathname.includes(authMenuSubItem.pageTabs[key].path)){
                        setPageTab(authMenuSubItem.pageTabs[key].index);
                        break;
                    }
                }                
            }
            else if('state' in location){
                if((location.state)&&('message' in location.state)){
                    alertMessage=location.state.message.alertMessage;
                    alertSeverity=location.state.message.alertSeverity;
                    setSnackOpened(true);
                }
            }
            setLabel(authMenuSubItem.label);
            listOfTags=globalData.listOfValues.PRODUCT_TAG||[];
            let tagValues=[];
            listOfTags.forEach(element=>{
                tagValues.push({title:element});
            });
            setLovValuesDynamic({PRODUCT_TAG:tagValues});
        }
    },[authMenuSubItem,slug,userDataAuthorization,location]);

    const handleClickSave=()=>{
        if(validate_required_fields(row,'product',setError,setErrorMessage,dataError,required_field_error)){
            if(('abv' in updates)&&isZero(updates.abv)&&('type' in row)&&(row.type!=='Merchandise')){
                //if(window.confirm("Is this is really an alcohol free product?")){
                const promptResponse=window.prompt("Is this is really an alcohol free product? If you are sure about this, please type 'alcohol free':");
                if(promptResponse==='alcohol free'){
                    preUpdateRecord();
                }
                else if(promptResponse!==null&&promptResponse!=='alcohol free'){
                    alertMessage="Invalid response. Please type 'alcohol free' to confirm the product is alcohol free";
                    alertSeverity='error';
                    setSnackOpened(true);
                }
            }
            else{
                preUpdateRecord();
            }
        }
        else{
            alertSeverity='error'
            alertMessage='Please populate the required fields';
            setSnackOpened(true);
        }
    }

    const handleClickCancel=()=>{
        setIsChanged(false);
        getRecord(authMenuSubItem.query);
        setUpdates(old=>{});
        setError(dataError.error);
        setErrorMessage(dataError.message);
    }

    const handleChange=(event)=>{
        setIsChanged(true);
        setRow(prevState=>({...prevState,[event.target.name]:event.target.value}));
        setUpdates(prevState=>({...prevState,[event.target.name]:event.target.value}));
        if(event.target.name in error){
            if(error[event.target.name]){
                setError(prevState=>({...prevState,[event.target.name]:event.target.value==''}));
                setErrorMessage(prevState=>({...prevState,[event.target.name]:event.target.value==''?required_field_error:''}));
            }
        }
        if('events' in entity_object.product.fields[event.target.name]){
            if('onChange' in entity_object.product.fields[event.target.name].events){
                entity_object.product.fields[event.target.name].events.onChange(row,event.target.value,handleChangeList,handleChange);
            }
        }
    };

    const handleClickButton=(event)=>{
        switch(event.target.name){
            case "update":
                setReadOnly(false);
                break;
            default: 
                break;
        }
    }

    const handleChangeSwitch=(event)=>{
        handleChange(event={target:{name:event.target.name,value:event.target.checked}});
    }

    const handleChangeList=(fieldValue,fieldName)=>{
        let event={target:{name:fieldName,value:fieldValue}}
        handleChange(event);
        if('events' in entity_object.product.fields[fieldName]){
            if('onChange' in entity_object.product.fields[fieldName].events){
                entity_object.product.fields[fieldName].events.onChange(row,fieldValue,handleChangeList,handleChange);
            }
        }
        if('drive_dynamic_required' in entity_object.product.fields[fieldName]){
            let row_update=JSON.parse(JSON.stringify(row));
            row_update[fieldName]=fieldValue;
            for(const [key,value] of Object.entries(entity_object.product.fields)){
                if(key!==fieldName){
                    if('required' in value){
                        if(typeof value.required==='function'){
                            let required=entity_object.product.fields[key].required(row_update);
                            setError(prevState=>({...prevState,[key]:required&&(row_update[key]==='')}));
                            setErrorMessage(prevState=>({...prevState,[key]:required&&(row_update[key]==='')?required_field_error:''}));
                        }
                    }
                }
            }
        }
    };

    const handleClickDrilldown=(field_name)=>{
        if(field_name==='account_name'){
            window.open('/account/'+row.account_id+'/orders','_blank');
        }
    }

    const checkDuplicated=()=>{
        duplicated_name={};
        check_duplicated({entity:"Product",field:"name",value:row.name,id:row.id})
        .then((data)=>{
            console.log('data',data);
            duplicated_name=data;
            if(duplicated_name.similar_entries_count>0){
                setOpenDialog(old=>true);
            }
            else{
                updateRecord();
            }
        });
    };
    const handleDialogCancel=()=>{
        setOpenDialog(false);
    }
    const CustomToolbar=()=>{
        return (
          <GridToolbarContainer>
            <GridToolbarQuickFilter autoFocus style={{right:'0px'}}></GridToolbarQuickFilter>
          </GridToolbarContainer>
        );
    }

    return (
        <div className="page">
            {pickControl.account_name&&<AccountPickList pickListCallback={pickListCallback} authMenuSubItem={authMenuSubItem} label={'Manufacturers'} globalData={globalData} userDataAuthorization={userDataAuthorization}></AccountPickList>}
            <Snackbar open={snackOpened} autoHideDuration={5000} onClose={handleCloseSnack} anchorOrigin={{vertical:'bottom',horizontal:'center'}}>
                <Alert variant="filled" severity={alertSeverity}>{alertMessage}</Alert>
            </Snackbar>
            <Dialog fullWidth={true} maxWidth={'lg'} open={openDialog} onClose={handleDialogCancel} PaperProps={{style:{minHeight:'75vh'}}}>
                <DialogTitle sx={{color:'darkorange'}}>{"Warning - Possible Duplicated Record ("+duplicated_name.similar_entries_count+")"}</DialogTitle>
                <DialogContent>
                    {"The Name of this Product ("+row.name+") has potential duplicated record(s). Would you like to proceed with the operation?"}
                    <div style={{height:'50vh',width:'100%'}}>
                        <DataGrid
                            sx={{'.MuiDataGrid-container--top [role=row]':{backgroundColor:'#f5f5f5'}}}
                            editMode="row"
                            rows={duplicated_name.similar_entries}
                            columns={columns.product_duplicated_name}
                            density="compact"
                            slots={{
                                toolbar:CustomToolbar,
                            }}
                            componentsProps={{
                                toolbar:{
                                    showQuickFilter:true,
                                    quickFilterProps:{debounceMs:250},
                                },
                            }}
                        />
                    </div>
                    {"(minimum score: "+(duplicated_name.similar_entries_thredshold*100).toFixed(2) +"%)"}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogCancel}>Cancel</Button>
                    <Button onClick={updateRecord} autoFocus variant="contained">Confirm</Button>
                </DialogActions>
            </Dialog>
            {<Backdrop sx={{color:'#fff',zIndex:(theme)=>theme.zIndex.drawer+1}} open={openBackdrop}><CircularProgress color="inherit"/></Backdrop>}
            {recordInfo&&<RecordInfo recordInfoCallback={recordInfoCallback} row={row} entity_name={'product'} globalData={globalData} userDataAuthorization={userDataAuthorization}></RecordInfo>}
            <Box sx={{display:'flex',flexDirection:'flex-end','& > *':{m:1,},}}>
                <Typography variant="h6" style={{margin:'5px'}}>{label}<span className="body-title">{row.name}</span></Typography>
            </Box>
            <div className="page-content">
                <Box component="form" noValidate autoComplete="off">
                    <ButtonGroup size="small">
                        {!readOnly&&<Button disabled={!isChanged} name="save" variant="outlined" onClick={handleClickSave}>Save</Button>}
                        {!readOnly&&<Button name="cancel" variant="outlined" onClick={handleClickCancel}>Cancel</Button>}
                        {Object.keys(actions).map((action)=>{
                            return(actions[action].type==='button'&&readOnly&&<Button key={action} name={action} variant="outlined" onClick={handleClickButton}>{actions[action].label}</Button>);
                        })}
                    </ButtonGroup>
                    {readOnly&&
                    <IconButton onClick={handleClickMoreInfo} color="primary" style={{position:'absolute',right:'40px'}} size="small">
                        <Tooltip title="More Information on the Record">
                            <InfoIcon fontSize="inherit"/>
                        </Tooltip>
                    </IconButton>}
                    {readOnly&&
                    <IconButton onClick={()=>{
                        setRow(old=>dataPreparedGet);
                        getRecord(authMenuSubItem.query)}} color="primary" style={{position:'absolute',right:'15px'}} size="small">
                        <Tooltip title="Refresh the current Record">
                            <RefreshIcon fontSize="inherit"/>
                            </Tooltip>
                    </IconButton>}
                    <Stack style={{paddingTop:'20px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            required={entity_object.product.fields.name.required}
                            error={error.name}
                            helperText={errorMessage.name}
                            id="name"
                            name="name"
                            type={entity_object.product.fields.name.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.product.fields.name.label}
                            value={row.name}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            InputProps={{readOnly:true}}
                            id="reference"
                            name="reference"
                            type={entity_object.product.fields.reference.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.product.fields.reference.label}
                            value={row.reference}
                            margin="normal"/>
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            fullWidth
                            readOnly={readOnly}
                            options={globalData.listOfValues.PRODUCT_TYPE||[]}
                            getOptionLabel={(option)=>option}
                            id="type"
                            name="type"
                            type={entity_object.product.fields.type.ui_type}
                            size="small"
                            value={row.type}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'type');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    required={entity_object.product.fields.type.required}
                                    error={error.type}
                                    helperText={errorMessage.type}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.product.fields.type.label}
                                    margin="normal"
                                />
                            )}
                        />
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            id="vat"
                            name="vat"
                            type={entity_object.product.fields.vat.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.product.fields.vat.label}
                            value={row.vat}
                            margin="normal"
                            onChange={handleChange}/>
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            fullWidth
                            required
                            readOnly={readOnly}
                            options={globalData.listOfValues.PRODUCT_STATUS||[]}
                            getOptionLabel={(option)=>option}
                            id="status"
                            name="status"
                            type={entity_object.product.fields.status.ui_type}
                            size="small"
                            value={row.status}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'status');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.product.fields.status.label}
                                    margin="normal"
                                />
                            )}
                        />
                    </Stack>
                    <Button startIcon={showMoreInfo?<ArrowCircleUp/>:<ArrowCircleDown/>} onClick={handleShowMoreInfo}>{showMoreInfo?hideMoreInfoLabel:showMoreInfoLabel}</Button>
                    {showMoreInfo&&<>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:(readOnly||entity_object.product.fields.abv.readonly(row))?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:(readOnly||entity_object.product.fields.abv.readonly(row))}}
                            required={entity_object.product.fields.abv.required(row)}
                            error={error.abv}
                            helperText={errorMessage.abv}
                            id="abv"
                            name="abv"
                            type={entity_object.product.fields.abv.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.product.fields.abv.label}
                            value={row.abv}
                            margin="normal"
                            onChange={handleChange}/>
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            fullWidth
                            sx={{span:{color:(readOnly||entity_object.product.fields.yeast_type.readonly(row))?'#1976d2':""}}}
                            readOnly={(readOnly||entity_object.product.fields.yeast_type.readonly(row))}
                            options={globalData.listOfValues.YEAST_TYPE||[]}
                            getOptionLabel={(option)=>option}
                            id="yeast_type"
                            name="yeast_type"
                            type={entity_object.product.fields.yeast_type.ui_type}
                            size="small"
                            value={row.yeast_type}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'yeast_type');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    //sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    //required={entity_object.product.fields.yeast_type.required}
                                    sx={{div:{backgroundColor:(readOnly||entity_object.product.fields.yeast_type.readonly(row))?readOnlyBackgroundColor:""}}}
                                    required={entity_object.product.fields.yeast_type.required(row)}
                                    error={error.yeast_type}
                                    helperText={errorMessage.yeast_type}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.product.fields.yeast_type.label}
                                    margin="normal"
                                />
                            )}
                        />
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            required={entity_object.product.fields.min_usage_days.required}
                            error={error.min_usage_days}
                            helperText={errorMessage.min_usage_days}
                            id="min_usage_days"
                            name="min_usage_days"
                            type={entity_object.product.fields.min_usage_days.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.product.fields.min_usage_days.label}
                            value={row.min_usage_days}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{div:{backgroundColor:readOnlyBackgroundColor}}}
                            InputProps={{
                                readOnly:true,
                                endAdornment:(
                                    row.account.name!==''&&
                                    readOnly&&
                                    <InputAdornment position="end">
                                        <IconButton onClick={(e)=>handleClickDrilldown('account_name')} color="primary">
                                            <ArrowCircleRight/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                /*
                                startAdornment:(
                                    !readOnly&&
                                    <InputAdornment position="start">
                                        <IconButton onClick={(e)=>handleClickPick('account_name')}>
                                            <AccountCircle/>
                                        </IconButton>
                                    </InputAdornment>
                                )*/
                            }}
                            id="account_name"
                            name="account_name"
                            type={entity_object.account.fields.name.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.product.joins.account.fields.name.label}
                            value={row.account.name}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            required={entity_object.product.fields.temperature_min.required}
                            error={error.temperature_min}
                            helperText={errorMessage.temperature_min}
                            id="temperature_min"
                            name="temperature_min"
                            type={entity_object.product.fields.temperature_min.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.product.fields.temperature_min.label}
                            value={row.temperature_min}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            required={entity_object.product.fields.temperature_max.required}
                            error={error.temperature_max}
                            helperText={errorMessage.temperature_max}
                            id="temperature_max"
                            name="temperature_max"
                            type={entity_object.product.fields.temperature_max.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.product.fields.temperature_max.label}
                            value={row.temperature_max}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            readOnly={readOnly}
                            sx={{span:{color:readOnly?'#1976d2':""}}}
                            fullWidth
                            options={globalData.listOfValues.PRODUCT_CATEGORY||[]}
                            id="category"
                            name="category"
                            type={entity_object.product.fields.category.ui_type}
                            size="small"
                            value={row.category}
                            ChipProps={{color:"primary"}}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'category');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                                    required={entity_object.product.fields.category.required(row)}
                                    error={error.category}
                                    helperText={errorMessage.category}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    label={entity_object.product.fields.category.label}
                                    margin="normal"/>
                            )}
                        />
                        {/*<Autocomplete
                            style={{marginTop:"0px"}}
                            readOnly={readOnly}
                            sx={{span:{color:readOnly?'#1976d2':""}}}
                            fullWidth
                            multiple
                            options={globalData.listOfValues.PRODUCT_CATEGORY||[]}
                            id="categories"
                            name="categories"
                            type={entity_object.product.fields.categories.ui_type}
                            size="small"
                            value={row.categories}
                            ChipProps={{color:"primary"}}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'categories');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    label={entity_object.product.fields.categories.label}
                                    margin="normal"/>
                            )}
                        />
                    </Stack>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                    */}
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            readOnly={readOnly}
                            sx={{span:{color:readOnly?'#1976d2':""}}}
                            fullWidth
                            freeSolo
                            multiple
                            options={lovValuesDynamic.PRODUCT_TAG}
                            id="tags"
                            name="tags"
                            type={entity_object.product.fields.tags.ui_type}
                            size="small"
                            value={productTags}
                            ChipProps={{color:"primary"}}
                            onChange={(event,newValue)=>{
                                setIsChanged(true);
                                newValue.forEach(element=>{
                                    if(typeof element!=='string'){
                                        if('inputValue' in element){
                                            if(!listOfTags.includes(element.inputValue)){
                                                updateDynamicListOfValues(element.inputValue);
                                            }
                                        }
                                    }
                                });
                                setProductTags(newValue);
                            }}
                            filterOptions={(options,params)=>{
                                const filtered=filter(options,params);
                                const {inputValue}=params;
                                const isExisting=options.some((option)=>inputValue===option.title);
                                if(inputValue!==''&&!isExisting){
                                    filtered.push({
                                        inputValue,
                                        title:`Add "${inputValue}"`,
                                    });
                                }
                                return filtered;
                            }}
                            getOptionLabel={(option)=>{
                                if(typeof option==='string'){
                                  return option;
                                }
                                if(option.inputValue){
                                  return option.inputValue;
                                }
                                return option.title;
                            }}
                            renderOption={(props,option)=>{
                                let bAdd=true;
                                productTags.every(element=>{
                                    if(typeof element!=='string'){
                                        if('inputValue' in element){
                                            bAdd=element.inputValue!==option.title;
                                        }
                                        else if('title' in element){
                                            bAdd=element.title!==option.title;
                                        }
                                    }
                                    return bAdd;
                                });
                                if(!productTags.includes(option.title)&&bAdd){
                                    return <li {...props}>{option.title}</li>;
                                }
                            }}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    label={entity_object.product.fields.tags.label}
                                    margin="normal"/>
                            )}
                        />
                    </Stack>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}> 
                        <FormGroup style={{paddingLeft:'10px'}}>
                            <FormControlLabel control={<Switch name='track_batches' checked={row.track_batches} onChange={handleChangeSwitch} disabled={readOnly}/>} label={entity_object.product.fields.track_batches.label}/>
                        </FormGroup>
                        <FormGroup style={{paddingLeft:'10px'}}>
                            <FormControlLabel control={<Switch name='track_expiration_date' checked={row.track_expiration_date} onChange={handleChangeSwitch} disabled={readOnly}/>} label={entity_object.product.fields.track_expiration_date.label}/>
                        </FormGroup>
                        <FormGroup style={{paddingLeft:'10px'}}>
                            <FormControlLabel control={<Switch name='batch_req_for_storage_order' checked={row.batch_req_for_storage_order} onChange={handleChangeSwitch} disabled={readOnly}/>} label={entity_object.product.fields.batch_req_for_storage_order.label}/>
                        </FormGroup>
                    </Stack>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            fullWidth
                            sx={{span:{color:(readOnly||entity_object.product.fields.tax_group_code.readonly(row))?'#1976d2':""}}}
                            readOnly={(readOnly||entity_object.product.fields.tax_group_code.readonly(row))}
                            options={globalData.listOfValues.PRODUCT_TAX_GROUP_CODE||[]}
                            getOptionLabel={(option)=>option}
                            id="tax_group_code"
                            name="tax_group_code"
                            type={entity_object.product.fields.tax_group_code.ui_type}
                            size="small"
                            value={row.tax_group_code}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'tax_group_code');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    //sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    //required={entity_object.product.fields.tax_group_code.required}
                                    sx={{div:{backgroundColor:(readOnly||entity_object.product.fields.tax_group_code.readonly(row))?readOnlyBackgroundColor:""}}}
                                    required={entity_object.product.fields.tax_group_code.required(row)}
                                    error={error.tax_group_code}
                                    helperText={errorMessage.tax_group_code}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.product.fields.tax_group_code.label}
                                    margin="normal"
                                />
                            )}
                        />
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            fullWidth
                            sx={{span:{color:(readOnly||entity_object.product.fields.product_group_code.readonly(row))?'#1976d2':""}}}
                            readOnly={(readOnly||entity_object.product.fields.product_group_code.readonly(row))}
                            options={
                                (row.tax_group_code in globalData.listOfValuesRel.PRODUCT_TAX_GROUP_CODE)?
                                (row.type==='Beer'?globalData.listOfValuesRel.PRODUCT_TAX_GROUP_CODE[row.tax_group_code].PRODUCT_GROUP_CODE.filter(item=>item.includes('eers')):(
                                    row.type==='Hard Seltzer'?globalData.listOfValuesRel.PRODUCT_TAX_GROUP_CODE[row.tax_group_code].PRODUCT_GROUP_CODE.filter(item=>!item.includes('eers')):
                                    globalData.listOfValuesRel.PRODUCT_TAX_GROUP_CODE[row.tax_group_code].PRODUCT_GROUP_CODE
                                )):[]}
                            getOptionLabel={(option)=>option}
                            id="product_group_code"
                            name="product_group_code"
                            type={entity_object.product.fields.product_group_code.ui_type}
                            size="small"
                            value={row.product_group_code}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'product_group_code');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    //sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    //required={entity_object.product.fields.product_group_code.required}
                                    sx={{div:{backgroundColor:(readOnly||entity_object.product.fields.product_group_code.readonly(row))?readOnlyBackgroundColor:""}}}
                                    required={entity_object.product.fields.product_group_code.required(row)}
                                    error={error.product_group_code}
                                    helperText={errorMessage.product_group_code}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.product.fields.product_group_code.label}
                                    margin="normal"
                                />
                            )}
                        />
                    </Stack>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            fullWidth
                            sx={{span:{color:(readOnly||entity_object.product.fields.customs_tariff_code.readonly(row))?'#1976d2':""}}}
                            readOnly={(readOnly||entity_object.product.fields.customs_tariff_code.readonly(row))}
                            options={globalData.listOfValues.PRODUCT_CUSTOMS_TARIFF_CODE||[]}
                            getOptionLabel={(option)=>option}
                            id="customs_tariff_code"
                            name="customs_tariff_code"
                            type={entity_object.product.fields.customs_tariff_code.ui_type}
                            size="small"
                            value={row.customs_tariff_code}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'customs_tariff_code');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    //sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    //required={entity_object.product.fields.customs_tariff_code.required}
                                    sx={{div:{backgroundColor:(readOnly||entity_object.product.fields.customs_tariff_code.readonly(row))?readOnlyBackgroundColor:""}}}
                                    required={entity_object.product.fields.customs_tariff_code.required(row)}
                                    error={error.customs_tariff_code}
                                    helperText={errorMessage.customs_tariff_code}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.product.fields.customs_tariff_code.label}
                                    margin="normal"
                                />
                            )}
                        />
                    </Stack>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            InputProps={{readOnly:readOnly}}
                            inputProps={{maxLength:500}}
                            sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            multiline={true}
                            rows={5}
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            id="description"
                            name="description"
                            type={entity_object.product.fields.description.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.product.fields.description.label}
                            value={row.description}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            InputProps={{readOnly:readOnly}}
                            sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            multiline={true}
                            rows={5}
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            id="description_internal"
                            name="description_internal"
                            type={entity_object.product.fields.description_internal.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.product.fields.description_internal.label}
                            value={row.description_internal}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack></>}
                </Box>
            </div>
            <Box sx={{bgcolor:'none'}}>
                <Tabs
                    value={pageTab}
                    onChange={handleChangeTab}
                    sx={{"& button:hover":{backgroundColor:'lightgrey'},"& button:focus":{backgroundColor:'white'},"& .MuiTab-root.Mui-selected":{backgroundColor:'white'},}}
                    variant="scrollable"
                    scrollButtons="auto">
                    {Object.keys(pageTabs).map((item)=>{
                        return (<Tab name={item} key={item} label={pageTabs[item].label}></Tab>);
                    })}
                </Tabs>
                {Object.keys(pageTabs).length>0&&('skus' in pageTabs)&&pageTab===pageTabs['skus'].index&&<ProductDetailSKUs parent={row} pageTab={('skus' in pageTabs)?pageTabs['skus']:{}} globalData={globalData} userDataAuthorization={userDataAuthorization}></ProductDetailSKUs>}
                {Object.keys(pageTabs).length>0&&('batches' in pageTabs)&&pageTab===pageTabs['batches'].index&&<ProductDetailBatches parent={row} pageTab={('batches' in pageTabs)?pageTabs['batches']:{}}></ProductDetailBatches>}
                {Object.keys(pageTabs).length>0&&('audit' in pageTabs)&&pageTab===pageTabs['audit'].index&&<Audit record_id={row.id} pageTab={('audit' in pageTabs)?pageTabs['audit']:{}} refreshPageTab={refreshPageTab} pageTabCallback={pageTabCallback} entity_name='product' globalData={globalData} userDataAuthorization={userDataAuthorization}></Audit>}
            </Box>
        </div>
    );
}

export default ProductDetail;
