import {useEffect,useState} from "react";
import {useNavigate} from 'react-router-dom';
import {ButtonGroup,Button,Stack,Snackbar,Alert} from '@mui/material';
import {DataGrid,GridToolbarContainer,GridToolbarQuickFilter} from '@mui/x-data-grid';
import {columns} from '../../utilities/Entities';
import {getDBList,deleteDBItem} from '../../integration/DatabaseManager.js';
import {useContext} from 'react';
import {ApplicationContext} from '../ApplicationContext.js';
import {saveUserDataGridParams,listGridUpdated} from '../../utilities/Utilities';
let alertMessage='';
let alertSeverity='error'
const list_name='sku_multipacks';

const SKUDetailMultipacks=({parent,pageTab,refreshPageTab,pageTabCallback,userDataAuthorization,globalData})=>{
    const [rows,setRows]=useState([]);
    const [loading,setLoading]=useState(true);
    const [snackOpened,setSnackOpened]=useState(false);
    const [selection,setSelection]=useState([]);
    const navigate=useNavigate();
    const {globalData1,updateGlobalData}=useContext(ApplicationContext);

    const handleCloseSnack=()=>{
        setSnackOpened(false);
    }

    const getRecords=(isSubscribed,query,query_args)=>{
        getDBList({entityType:query,entityParams:['parent_sku_id='+parent.id,'type=Multipack']})
        .then((data)=>{
            if(isSubscribed){
                if('error' in data){console.log('error',data.error);}
                else{
                    if(data.length>0){
                        console.log('records',data);
                        setRows(old=>data);
                    }
                    else{
                        setRows([]);
                    }
                }
                setLoading(false);
                if(refreshPageTab){
                    pageTabCallback('refreshed');
                }
            }
        });
    };

    useEffect(()=>{
        let isSubscribed=true;
        if(Object.keys(parent).length>0){
            if(parent.id!==undefined&&parent.id!==null&&parent.id!==''){
                getRecords(isSubscribed,pageTab.query,pageTab.query_args);
            }
        }
        return ()=>(isSubscribed=false);
    },[parent.id,pageTab]);

    const handleClickListButton=(event)=>{
        switch(event.target.name){
            case "add_multipack":
                console.log(parent);
                let parent_sku={
                    id:parent.id,
                    name:parent.name,
                    palpa:parent.palpa,
                    palpa_value:parent.palpa_value,
                    product:{
                        temperature_min:parent.product.temperature_min,
                        temperature_max:parent.product.temperature_max,
                        description:parent.product.description,
                        description_internal:parent.product.description_internal,
                        min_usage_days:parent.product.min_usage_days,
                        track_expiration_date:parent.product.track_expiration_date,
                        track_batches:parent.product.track_batches,
                        //categories:parent.product.categories,
                        category:parent.product.category,
                        tags:parent.product.tags,
                        id:parent.product.id,
                        name:parent.product.name
                    }
                }
                navigate('/sku/new',{state:{parent_sku:parent_sku}});
                break;
            default: 
                break;
        }
    }

    const deleteRecord=(order_item_id,refresh)=>{
        deleteDBItem({entityType:pageTab.query, entityId:order_item_id})
        .then((data)=>{
            if('error' in data){
                console.log('error',data.error);
                alertSeverity='error'
                alertMessage=data.error;
                setSnackOpened(true);
            }
            else{
                console.log('update',data);
                alertSeverity='success';
                alertMessage='Multipack(s) removed with success!!!';
                setSnackOpened(true);
                if(refresh){
                    getRecords(true,pageTab.query,pageTab.query_args);
                }
            }
        });
      };

    const handleSelection=(ids)=>{
        setSelection(old=>ids);
    };

    const handleClickRemove=()=>{
        selection.forEach(function callback(element,index){
            deleteRecord(element,index===selection.length-1);
        });
    }

    const CustomToolbar=()=>{
        return (
          <GridToolbarContainer>
            <GridToolbarQuickFilter style={{right:'0px'}}></GridToolbarQuickFilter>
          </GridToolbarContainer>
        );
    }

    return(
        <div className="page-content">
            <Snackbar open={snackOpened} autoHideDuration={3000} onClose={handleCloseSnack} anchorOrigin={{vertical:'bottom',horizontal:'center'}}>
                <Alert variant="filled" severity={alertSeverity}>{alertMessage}</Alert>
            </Snackbar>
            <ButtonGroup size="small" aria-label="small button group">
                {/*<Button disabled={selection.length<=0} name="remove" variant="outlined" onClick={handleClickRemove}>Remove</Button>*/}
                {Object.keys(pageTab).length>0&&Object.keys(pageTab.actions).map((action)=>{
                    return(pageTab.actions[action].type==='button'&&<Button key={action} name={action} variant="outlined" onClick={handleClickListButton}>{pageTab.actions[action].label}</Button>);
                })}
            </ButtonGroup>
            <Stack style={{paddingTop:'15px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                <div style={{height:'50vh',width:'100%'}}>
                    <DataGrid
                        sx={{'.MuiDataGrid-container--top [role=row]':{backgroundColor:'#f5f5f5'}}}
                        editMode="row"
                        onRowSelectionModelChange={handleSelection}
                        disableRowSelectionOnClick
                        disableSelectionOnClick
                        initialState={{
                            sorting:{
                                sortModel:[{field:'name',sort:'asc'}],
                            },
                        }}
                        rows={rows}
                        columns={columns[list_name](list_name in globalData.dataGridParams?globalData.dataGridParams[list_name]:{})}
                        loading={loading}
                        density="compact"
                        slots={{
                            toolbar:CustomToolbar,
                        }}
                        componentsProps={{
                            toolbar:{
                                showQuickFilter:true,
                                quickFilterProps:{debounceMs:250},
                            },
                        }}
                        onColumnWidthChange={(params,event,details)=>{
                            let listGridUpdatedNew=listGridUpdated(globalData.dataGridParams,list_name,params.colDef.field,params.width);
                            updateGlobalData({...globalData,dataGridParams:listGridUpdatedNew});
                            saveUserDataGridParams(listGridUpdatedNew,userDataAuthorization.user_id);
                        }}
                    />
                </div>
            </Stack>
        </div>);
}

export default SKUDetailMultipacks;
